import { collection, getDocs, orderBy, query } from 'firebase/firestore';
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { db } from '../subscribe/firebase';

const TopBanner = () => {
    const [scrollPosition, setScrollPosition] = useState(0);
    const [isVisible, setIsVisible] = useState(false);
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const [newsContent, setNewsContent] = useState([]);

    useEffect(() => {
        const fetchNews = async () => {
            try {
                const newsCollection = collection(db, 'news');
                const newsQuery = query(newsCollection, orderBy('timestamp', 'desc'));
                const newsSnapshot = await getDocs(newsQuery);
                const allNews = newsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

                // Filter news for top banner
                const filteredNews = allNews.filter(news => news.position === 'top');
                setNewsContent(filteredNews);
            } catch (error) {
                console.error('Failed to fetch news:', error);
            }
        };

        fetchNews();
    }, []);

    const fetchNews = async () => {
        try {
            const newsCollection = collection(db, 'news');
            const newsQuery = query(newsCollection, orderBy('timestamp', 'desc'));
            const newsSnapshot = await getDocs(newsQuery);
            const allNews = newsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

            // Filter news for top banner
            const filteredNews = allNews.filter(news => news.position === 'top');
            setNewsContent(filteredNews);
        } catch (error) {
            console.error('Failed to fetch news:', error);
        }
    };

    return (
        <div>
            <div className='lg:flex flex-col text-white large-screen-image cursor-pointer z-50'>
                {newsContent.slice(0, 1).map((news) => (
                    <div className='md:top-[360px] md:absolute xl:px-20   2xl:px-40 lg:px-4 md:px-3 px-3 md:w-[30%] xl:w-[480px]  2xl:w-[660px] flex gap-3'>
                        <Link to={`/news/${news.id}`} className='flex gap-3 cursor-pointer'>
                            <img src={news.imageUrl} alt="" className='rounded-md xl:w-32 w-20  h-20' />
                            <h2 className='font-extrabold'>
                                {news.heading.length > 50 ? `${news.heading.slice(0, 50)}...` : news.heading}
                            </h2>
                        </Link>
                    </div>
                ))}
                {newsContent.slice(1, 2).map((news) => (
                    <div className='md:top-[360px] md:absolute md:py-0   py-3 xl:px-20 2xl:px-40 lg:px-4 md:px-3 px-3 md:right-[300px] lg:left-[360px] lg:right-[360px] xl:left-[450px] xl:right-[550px] 2xl:left-[450px] 2xl:right-[450px] md:w-[30%] xl:w-[480px] 2xl:w-[660px] flex gap-3'>
                        <Link to={`/news/${news.id}`} className='flex gap-3 cursor-pointer'>
                            <img src={news.imageUrl} alt="" className='rounded-md xl:w-32 w-20  h-20' />
                            <h2 className='font-extrabold'>
                                {news.heading.length > 50 ? `${news.heading.slice(0, 50)}...` : news.heading}
                            </h2>
                            {/* <h2>hello</h2> */}
                        </Link>
                    </div>
                ))}
                {newsContent.slice(2, 3).map((news) => (
                    <div className='md:top-[360px] md:absolute xl:px-20   2xl:px-40 lg:px-4 md:px-3 px-3 right-0  md:w-[30%] xl:w-[480px]  2xl:w-[660px] flex gap-3'>
                        <Link to={`/news/${news.id}`} className='flex gap-3 cursor-pointer'>
                            <img src={news.imageUrl} alt="" className='rounded-md xl:w-32 w-20  h-20' />
                            <h2 className='font-extrabold'>
                                {news.heading.length > 50 ? `${news.heading.slice(0, 50)}...` : news.heading}
                            </h2>
                        </Link>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default TopBanner