import React, { useEffect, useState } from 'react';
import { storage } from '../subscribe/firebase'; // Make sure to export your Firebase configuration from firebase.js
import { ref, listAll, getDownloadURL } from 'firebase/storage';
import Header from './Header';
import Footer from './Footer';

const TransferPostingNews = () => {
    const [pdfs, setPdfs] = useState([]);

    useEffect(() => {
        const fetchPDFs = async () => {
            try {
                const pdfRef = ref(storage, 'pdfs/');
                const res = await listAll(pdfRef);

                const pdfUrls = await Promise.all(
                    res.items.map(async (itemRef) => {
                        const url = await getDownloadURL(itemRef);
                        return { name: itemRef.name, url };
                    })
                );

                setPdfs(pdfUrls);
            } catch (error) {
                console.error('Error fetching PDFs:', error);
            }
        };

        fetchPDFs();
    }, []);

    return (
        <div className="container mx-auto ">
            <Header />
            <div className="flex flex-wrap p-6 gap-6">
                {pdfs.length > 0 ? (
                    pdfs.map((pdf) => (
                        <div key={pdf.name} className="bg-white border border-gray-300 rounded-lg shadow-md p-4 w-full sm:w-1/2 lg:w-1/3">
                            <h3 className="text-lg font-semibold mb-2">{pdf.name}</h3>
                            <a href={pdf.url} rel="noopener noreferrer" className="block text-blue-500 hover:text-blue-700">
                                <button className="w-full bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-opacity-50">
                                    View PDF
                                </button>
                            </a>
                        </div>
                    ))
                ) : (
                    <p className="text-gray-500 w-40 h-40"><img src={require('../assets/image/logo.png')} alt="" /></p>
                )}
            </div>
            <Footer />
        </div>
    );
};

export default TransferPostingNews;
