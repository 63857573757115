import React, { useEffect, useState } from 'react'
import { deleteDoc, doc, getDocs } from 'firebase/firestore';
import { db, storage } from '../subscribe/firebase'; // Adjust the path as needed
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import imageCompression from 'browser-image-compression';
import { addDoc, collection, serverTimestamp } from 'firebase/firestore';
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';

const Banner = () => {
    const [newsList, setNewsList] = useState([]);
    const [loading, setLoading] = useState(true);
    const [newsContent, setNewsContent] = useState({
        image: null,
        imageUrl: '',
        heading: '',
    });
    const [uploadProgress, setUploadProgress] = useState(0);
    const handleUpload = async () => {
        if (!newsContent.imageUrl) {
            toast.error('Please fill in all required fields.');
            return;
        }

        try {
            if (newsContent.image) {
                // Compress the image before uploading
                const options = {
                    maxSizeMB: 1, // Maximum size in MB
                    maxWidthOrHeight: 1920, // Maximum width or height
                    useWebWorker: true, // Use multi-threading for faster compression
                };

                const compressedFile = await imageCompression(newsContent.image, options);

                const storageRef = ref(storage, `images/${compressedFile.name}`);
                const uploadTask = uploadBytesResumable(storageRef, compressedFile);

                uploadTask.on(
                    'state_changed',
                    (snapshot) => {
                        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                        setUploadProgress(progress);
                    },
                    (error) => {
                        toast.error(`Image upload failed: ${error.message}`);
                    },
                    async () => {
                        try {
                            const imageUrl = await getDownloadURL(uploadTask.snapshot.ref);
                            await addDoc(collection(db, 'banner'), {
                                imageUrl,
                                heading: newsContent.heading,
                                timestamp: serverTimestamp()
                            });
                            // resetNewsContent();
                            toast.success('News content uploaded successfully!');
                            // fetchNews();
                        } catch (error) {
                            toast.error(`Failed to get download URL: ${error.message}`);
                        }
                    }
                );
            } else {
                await addDoc(collection(db, 'banner'), {
                    imageUrl: '',
                    heading: '',
                    timestamp: serverTimestamp()
                });
                // resetNewsContent();
                toast.success('News content uploaded successfully!');
                // fetchNews();
            }
        } catch (error) {
            toast.error(`Failed to upload news: ${error.message}`);
        }
    };

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setNewsContent(prevState => ({
                ...prevState,
                image: file,
                imageUrl: URL.createObjectURL(file)
            }));
        }
    };
    const handleNewsChange = (e) => {
        const { name, value } = e.target;
        setNewsContent(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    useEffect(() => {
        const fetchNews = async () => {
            try {
                const newsCollection = collection(db, 'banner');
                const newsSnapshot = await getDocs(newsCollection);
                const newsList = newsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
                setNewsList(newsList);
                setLoading(false);
            } catch (error) {
                toast.error(`Failed to fetch news: ${error.message}`);
                setLoading(false);
            }
        };


        fetchNews();
    }, []);

    const fetchNews = async () => {
        try {
            const newsCollection = collection(db, 'banner');
            const newsSnapshot = await getDocs(newsCollection);
            const newsList = newsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
            setNewsList(newsList);
            setLoading(false);
        } catch (error) {
            toast.error(`Failed to fetch news: ${error.message}`);
            setLoading(false);
        }
    };




    return (
        <div className="form-group">
            <form className="news-form">
                <div className="form-group">
                    <label>Heading:</label>
                    <input
                        type="text"
                        name="heading"
                        value={newsContent.heading}
                        onChange={handleNewsChange}
                        className="form-control"
                    />
                </div>
                {/* <div className="form-group">
                    <label>Paragraph:</label>
                    <textarea
                        name="paragraph"
                        value={newsContent.paragraph}
                        onChange={handleNewsChange}
                        className="form-control"
                        rows={10}
                    ></textarea>
                </div>
                <div className="form-group">
                    <label>View Paragraph:</label>
                    <textarea
                        name="view_paragraph"
                        value={newsContent.view_paragraph}
                        onChange={handleNewsChange}
                        className="form-control"
                    ></textarea>
                </div> */}
                <div className="form-group">
                    <label>Image:</label>
                    <input
                        type="file"
                        name="image"
                        accept="image/*"
                        onChange={handleImageChange}
                        className="form-control"
                    />
                    {newsContent.imageUrl && (
                        <img
                            src={newsContent.imageUrl}
                            alt="Uploaded"
                            className="mt-2"
                            style={{ maxWidth: '200px', height: 'auto' }}
                        />
                    )}
                    <p className='w-full p-2 border-green-600 text-white rounded-md'>Progress: {uploadProgress}%</p>
                </div>
                {/* <div className='flex items-center gap-3'>
                    <label>News Type:</label>
                    <select name="type" value={newsContent.type} onChange={handleNewsChange}>
                        <option value="simple">Simple</option>
                        <option value="premium">Premium</option>
                    </select>
                </div> */}
                {/* <div className="form-group">
                    <label>Pages:</label> */}
                {/* <div>
                        <label>
                            <input type="checkbox" value="home" checked={newsContent.pages.includes('home')} onChange={handlePageChange} />
                            Home
                        </label>
                        <label>
                            <input type="checkbox" value="custom" checked={newsContent.pages.includes('custom')} onChange={handlePageChange} />
                            Custom
                        </label>
                        <label>
                            <input type="checkbox" value="dri" checked={newsContent.pages.includes('dri')} onChange={handlePageChange} />
                            DRI
                        </label>
                        <label>
                            <input type="checkbox" value="dgji" checked={newsContent.pages.includes('dgji')} onChange={handlePageChange} />
                            DGJI
                        </label>
                        <label>
                            <input type="checkbox" value="politics" checked={newsContent.pages.includes('politics')} onChange={handlePageChange} />
                            Politics
                        </label>
                        <label>
                            <input type="checkbox" value="cbi" checked={newsContent.pages.includes('cbi')} onChange={handlePageChange} />
                            CBI
                        </label>
                        <label>
                            <input type="checkbox" value="gold-smuggling" checked={newsContent.pages.includes('gold-smuggling')} onChange={handlePageChange} />
                            Gold Smuggling
                        </label>
                        <label>
                            <input type="checkbox" value="health" checked={newsContent.pages.includes('health')} onChange={handlePageChange} />
                            Health
                        </label>
                        
                    </div> */}
                {/* </div> */}
                <button type="button" onClick={handleUpload} className="upload-button bg-footerColor text-white px-4 py-2 mt-4">
                    Upload
                </button>
            </form>
            {/* {newsContent.imageUrl && (
                <img
                    src={newsContent.imageUrl}
                    alt="Uploaded"
                    className="mt-2"
                    style={{ maxWidth: '200px', height: 'auto' }}
                />
            )}
            <p>Progress: {uploadProgress}%</p> */}
            {/* <button type="button" onClick={handleUpload} className="upload-button bg-footerColor text-white px-4 py-2 mt-4">
                Upload
            </button> */}
        </div>
    )
}

export default Banner