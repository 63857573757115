import "./App.css";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Home from "./views/Home";
import React, { useEffect, useState } from "react";
import NewsDetails from "./components/NewsDetails";
import PreminumPage from "./subscribe/PreminumPage";
import Custom from "./views/Custom";
import DRI from "./views/DRI"
import CBI from "./views/CBI"
import DGJI from "./views/DGJI"
import Gold from "./views/Gold"
import Politics from "./views/Politics"
import Health from "./views/Health";
import Login from "./subscribe/Login";
import Register from "./subscribe/Register";
import { auth } from "./subscribe/firebase";
import { ToastContainer } from "react-toastify";
import Header from "./views/Header";
import AdminForm from "./admin/AdminForm";
import Admin from "./admin/Admin";
import Contact from "./components/Contact";
import Policy from "./components/Policy";
import TermConditions from "./components/TermConditions";
import WhoWeAre from "./components/WhoWeAre";
import BannerNewsDetails from "./components/BannerNewsDetails";
import GST from "./views/GST";
import Enforcement from "./views/Enforcement";
import Footer from "./views/Footer";
import Narotics from "./views/Narotics";
import Transfer from "./views/Transfer";
import Legal from "./views/Legal";
import IncomeTax from "./views/IncomeTax";
import Editorial from "./views/Editorial";
import Profile from "./views/Profile";
import EditPassword from "./components/EditPassword";
import Request from "./components/Request";
import Interview from "./views/Inteview";


export default function App() {
  const [user, setUser] = useState();
  useEffect(() => {
    auth.onAuthStateChanged((user) => {
      setUser(user);
    });
  });
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setUser(user); // Set user state based on Firebase auth state
    });

    return () => unsubscribe(); // Cleanup on unmount
  }, []);
  const handleLogout = () => {
    auth.signOut().then(() => {
      setUser(null);
    }).catch(error => {
      console.error("Logout Error: ", error);
    });
  };

  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/pricing" element={<PreminumPage />} />
          <Route path="/custom" element={<Custom />} />
          <Route path="/dri" element={<DRI />} />
          <Route path="/gst" element={<GST />} />
          <Route path="/enforcement-directorate" element={<Enforcement />} />
          <Route path="/income-tax-department" element={<IncomeTax />} />
          <Route path="/narcotics-control-bureau" element={<Narotics />} />
          <Route path="/transfer-posting" element={<Transfer />} />
          <Route path="/legal-advice" element={<Legal />} />
          <Route path="/interviews" element={<Interview />} />
          <Route path="/editorial" element={<Editorial />} />
          <Route path="/dggi" element={<DGJI />} />
          <Route path="/politics" element={<Politics />} />
          <Route path="/cbi" element={<CBI />} />
          <Route path="/gold-smugling" element={<Gold />} />
          <Route path="/health" element={<Health />} />
          <Route path="/contact-us" element={<Contact />} />
          <Route path="/privacy-policy" element={<Policy />} />
          <Route path="/term-and-conditions" element={<TermConditions />} />
          <Route path="/who-we-are" element={<WhoWeAre />} />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/forgotpassword" element={<EditPassword />} />
          <Route path="/request" element={<Request />} />
          <Route path="/revenue-news-channel-adminpanel" element={<Admin />} />
          <Route path="/admin-form" element={<AdminForm />} />
          <Route path="/news/:id" element={<NewsDetails />} />
          <Route path="/popular-news/:id" element={<NewsDetails />} />
          <Route path="/premium-news-detail/:id" element={<BannerNewsDetails />} />
          <Route path="*" element={<Home />} />
        </Routes>
        {/* <Footer /> */}
        {/* <ToastContainer /> */}
      </BrowserRouter>
    </div>
  );
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<App />);
