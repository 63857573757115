import React, { useEffect, useState } from 'react';
import { IoLogoFacebook, IoLogoInstagram, IoLogoLinkedin, IoLogoTwitter, IoLogoWhatsapp, IoLogoYoutube, IoSearch } from "react-icons/io5";
import { useMediaQuery } from "react-responsive";
import { AiOutlineMenu, AiFillCaretDown } from "react-icons/ai";
import { IoCloseSharp } from "react-icons/io5";
import { auth, db } from "../subscribe/firebase";
import { collection, doc, getDoc, getDocs, orderBy, query } from "firebase/firestore";
import { Link, useNavigate } from 'react-router-dom';
import { FaAngleDown, FaAviato, FaPhone, FaSave } from 'react-icons/fa';
import { RxAvatar } from 'react-icons/rx';
import { toast } from 'react-toastify';
import { signOut } from 'firebase/auth';
import axios from 'axios';
import SearchBar from '../components/SearchBar';

const Header = ({ user, onLogout, onSearch }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [menuOpen, setMenuOpen] = useState(false);
  const [isMenuFixed, setIsMenuFixed] = useState(false);
  const [isAccountMenuOpen, setIsAccountMenuOpen] = useState(false);
  const [users, setUsers] = useState(null); // User state
  const isSmallScreen = useMediaQuery({ maxWidth: "992px" });
  const navigate = useNavigate();
  const [userDetails, setUserDetails] = useState(null);
  const [newsList, setNewsList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showMenu, setShowMenu] = useState(false);
  const [filteredNews, setFilteredNews] = useState([]);
  const [searchInput, setSearchInput] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [isDropdownVisible, setDropdownVisible] = useState(false);
  // const navigate = useNavigate();

  const handleInputChange = async (e) => {
    const value = e.target.value;
    setSearchInput(value);
    if (value) {
      await fetchSearchResults(value);
    } else {
      setSearchResults([]);
    }
  };

  const fetchSearchResults = async (query) => {
    try {
      const response = await axios.get(`API_ENDPOINT/search?query=${query}`);
      setSearchResults(response.data.results);
      setDropdownVisible(true);
    } catch (error) {
      console.error('Error fetching search results:', error);
    }
  };

  const handleResultClick = (result) => {
    navigate(`/news/${result.id}`);
    setSearchInput('');
    setSearchResults([]);
    setDropdownVisible(false);
  };

  const handleMouseEnter = () => setShowMenu(true);
  const handleMouseLeave = () => setShowMenu(false);

  const fetchUserData = async () => {
    auth.onAuthStateChanged(async (user) => {
      console.log(user);

      const docRef = doc(db, "Users", user.uid);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        setUserDetails(docSnap.data());
        console.log(docSnap.data());
      } else {
        console.log("User is not logged in");
      }
    });
  };

  useEffect(() => {
    fetchUserData();
  }, []);


  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 100) { // Adjust the scroll threshold as needed
        setIsMenuFixed(true);
      } else {
        setIsMenuFixed(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const toggleAccountMenu = () => {
    setIsAccountMenuOpen(!isAccountMenuOpen);
  };

  const handleChange = (event) => {
    setSearchTerm(event.target.value);
    const filtered = newsList.filter(news =>
      news.heading.toLowerCase().includes(event.target.value.toLowerCase())
    );
    setFilteredNews(filtered);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    onSearch(searchTerm);
  };

  const handleLogout = async () => {
    onLogout();
  };

  useEffect(() => {
    const fetchNews = async () => {
      try {
        const newsCollection = collection(db, 'banner-news');
        const newsSnapshot = await getDocs(newsCollection);
        const newsList = newsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setNewsList(newsList);
        setLoading(false);
      } catch (error) {
        toast.error(`Failed to fetch news: ${error.message}`);
        setLoading(false);
      }
    };


    fetchNews();
  }, []);

  return (
    <div className=''>
      <div className='w-full h-20 py-4 flex container mx-auto md:justify-cente justify-between -z-50 '>
        <div className='small-screen-image'>
          {menuOpen ? (
            <IoCloseSharp
              onClick={toggleMenu}
              size={22}
              className="cursor-pointer ml-3 menu-container"
            />
          ) : (
            <AiOutlineMenu
              onClick={toggleMenu}
              size={22}
              className="cursor-pointer ml-3"
            />
          )}
          {menuOpen && (
            <div className="menu-containe z-50">
              <div className={`flex text-white flex-col z-50 bg-black w-full pl-28 p-6 absolute gap-6 menu-items ${menuOpen ? '-translate-x-20 transform transition-transform duration-300 ease-in-out menu-container' : '-translate-x-100'}`}>
                {/* <Link to="/">Home</Link> */}
                <Link to="/">कस्टम</Link>
                <Link to="/gst">जीएसटी</Link>
                <Link to="/enforcement-directorate">प्रवर्तन निदेशालय ( ईडी )</Link>
                <Link to="/dri">डीआरआई</Link>
                <Link to="/dggi">डीजीजीआई</Link>
                <Link to="/income-tax-department">आयकर विभाग</Link>
                <Link to="/cbi">सीबीआई</Link>
                <Link to="/narcotics-control-bureau">नारकोटिक्स कंट्रोल ब्यूरो</Link>
                <Link to="/gold-smugling">सोना तस्करी</Link>
                <Link to="/transfer-posting">ट्रांसफर-पोस्टिंग</Link>
                <Link to="/politics">राजनिति</Link>
                <Link to="/legal-advice">कानूनी सलाह</Link>
                <Link to="/interviews">साक्षात्कार</Link>
                <Link to="/editorial">संपादकीय</Link>

                <div className='border-t gap-6'>
                  <div className='md:flex gap-6 py-6'>
                    <Link to='/who-we-are'>
                      <h2 className='font-bold'>हम कौन हैं</h2>
                    </Link>
                    <Link to='/contact-us'>
                      <h2 className='font-bold py-6'>संपर्क करें</h2>
                    </Link>
                    <Link to='/privacy-policy'>
                      <h2 className='font-bold'>गोपनीयता नीति</h2>
                    </Link>
                    <Link to='/term-and-conditions'>
                      <h2 className='font-bold py-6'>नियम और शर्तें</h2>
                    </Link>
                  </div>
                  <div className='flex items-center xl:gap-6 gap-6 '>
                    <IoLogoFacebook size={25} />
                    <IoLogoTwitter size={25} />
                    <IoLogoWhatsapp size={25} />
                    <IoLogoYoutube size={25} />
                    <IoLogoLinkedin size={25} />
                    <IoLogoInstagram size={25} />
                  </div>
                  <Link to="/login">
                    <button className='hover:bg-red-100 hover:shadow-md border w-full my-6 bg-white border-red-600 text-red-600 p-2 md:h-12 xl:px-8 lg:px-4 md:px-2 lg:mx-1 xl:mx-3 rounded-md 2xl:text-base text-sm'>
                      Existing User
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          )}
        </div>
        <div className='xl:px-10 2xl:pl-40 lg:px-20 md:px-10 px-3 mr-20 '>
          <Link to="/">
            <img src={require("../assets/image/logo.png")} alt="Logo" className='md:w-20 w-16 md:mb-3 mb-3' />
          </Link>
        </div>
        {/* <div className='flex items-center md:w-[35%] large-screen-image'>
          <form onSubmit={handleSubmit} className='flex w-full'>
            <input
              type="text"
              placeholder=""
              value={searchTerm}
              onChange={handleChange}
              className='border p-2 w-full  md:px-3 '
            />
            <button type="submit" className='bg-red-600 p-3 px-8 text-white mx-3 rounded-md'><IoSearch /></button>
          </form>
        </div> */}

        <div className='large-screen-image w-[50%]'>
          <SearchBar />
        </div>

       


        {user && userDetails ? ( // Render account-related UI if user is authenticated
          <div className='relative 2xl:mr-40 xl:mr-20 mr-0 z-50'>
            <h2 className='text-xl text-gray-600 flex justify-center items-center cursor-pointer border w-full rounded-md p-2 ' onClick={toggleAccountMenu}>
              Account <AiFillCaretDown className='ml-2' />
            </h2>
            {isAccountMenuOpen && userDetails && (
              <div className='absolute right-0 mt-2 w-56 bg-white rounded-sm drop-shadow-lg py-1 z-50 '>
                <div className='flex flex-col px-6 text-sm'>
                  {/* <div className='rounded-full flex flex-col'>
                    {user.photoURL ? (
                      <img src={user.photoURL} alt="" className='w-12 h-12 rounded-full mb-2' />
                      ) : (
                        <RxAvatar size={25} />
                        )}
                  </div> */}
                  <h2 className='text-red-600 my-2'>{userDetails.fullName}</h2>
                  <h2 className=''>
                    {userDetails.subscription === 'premium' ? 'You are a premium subscriber.' : 'You are not a premium subscriber, You have limited access to LiveLaw'}
                  </h2>
                  <Link to="/pricing">
                    <button className='bg-red-600  text-white p-1 rounded-3xl text-sm my-2 shadow-md'>Subscribe Now For Unlimited Access</button>
                  </Link>
                </div>
                <div className='px-6 p-2  hover:bg-[#F6F6F6]'>
                  <Link to={`/profile?id=${user?.uid}`}>
                    <h2 className='flex gap-3 text-sm'><RxAvatar size={20} className='text-[#878787]' /> Profile</h2>
                  </Link>
                </div>
                <div className='px-6 p-2  hover:bg-[#F6F6F6]'>
                  <Link to={`/profile?id=${user?.uid}`}>
                    <h2 className='flex gap-3 text-sm'><FaSave size={20} className='text-[#878787]' /> Saved Article</h2>
                  </Link>
                </div>
                <div className='px-6 p-2  hover:bg-[#F6F6F6]'>
                  <Link to={'/contact-us'}>
                    <h2 className='flex gap-3 text-sm'><FaPhone size={20} className='text-[#878787]' /> Help</h2>
                  </Link>
                </div>
                <div className='border w-full' />
                <button className=' text-red-600 p-2 px-6 mb-2 text-sm' onClick={handleLogout}>
                  Log Out
                </button>
              </div>
            )}
          </div>
        ) : (
          // Render login and signup buttons if user is not authenticated
          <div className='flex 2xl:pr-40 xl:pr-20 pr-0'>
            <Link to="/login">
              <button style={{whiteSpace: 'nowrap'}} className='hover:bg-red-100 large-screen-image hover:shadow-md border hover:border-red-100 border-red-600 text-red-600 p-2 md:h-12 xl:px-8 lg:px-4 md:px-2 lg:mx-1 xl:mx-3 rounded-md 2xl:text-base text-sm'>
                Existing User
              </button>
            </Link>
            <Link to="/pricing">
              <button style={{whiteSpace: 'nowrap'}} className='bg-red-600 md:p-2 p-1 xl:px-8 lg:px-4 md:px-2 text-white lg:mx-1 xl:mx-3 rounded-md md:h-12 2xl:text-base text-sm mx-3'>
                Subscribe <span>Premium</span>
              </button>
            </Link>
          </div>
        )}
        {/* </div>
    </div>
  );
} */}
      </div>

      <div className='flex items-center small-screen-image px-3 py-2 -z-[999]'>
        <SearchBar />
        {/* <form onSubmit={handleSubmit} className='flex'>
          <input
            type="text"
            placeholder=""
            value={searchTerm}
            onChange={handleChange}
            className='border w-full p-2 '
          />
          <button type="submit" className='bg-red-600 p-2 px-8 text-white mx-3 rounded-md'><IoSearch /></button>
        </form> */}
      </div>



      <div className={`bg-[#262626] menu1 px-12 large-screen-image ${isMenuFixed ? 'fixed top-0 w-full z-50' : ''}`}>
        <div className='text-white flex justify-center items-center gap-6 xl:gap-12 xl:px-10 2xl:px-30 lg:px-20 md:px-10 p-3 container mx-auto w-full'>
          <Link to="/">कस्टम</Link>
          <Link to="/gst">जीएसटी</Link>
          <Link to="/enforcement-directorate">प्रवर्तन निदेशालय ( ईडी )</Link>
          <Link to="/dri">डीआरआई</Link>
          <Link to="/dggi">डीजीजीआई</Link>
          <Link to="/income-tax-department">आयकर विभाग</Link>
          <Link to="/cbi">सीबीआई</Link>
          <Link to="/narcotics-control-bureau">नारकोटिक्स कंट्रोल ब्यूरो</Link>
          <h2
            className="relative"
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            <span className="cursor-pointer hover:text-gray-300 flex gap-1">More <FaAngleDown className='mt-1' size={18} /></span>
            {showMenu && (
              <div className="absolute top-4 z-50 -left-80 mt-2 w-96 bg-gray-800 shadow-lg p-2">
                <Link to="/gold-smugling" className="block px-4 py-2 hover:bg-gray-700 rounded-md">सोना तस्करी</Link>
                <Link to="/transfer-posting" className="block px-4 py-2 hover:bg-gray-700 rounded-md">ट्रांसफर-पोस्टिंग</Link>
                <Link to="/politics" className="block px-4 py-2 hover:bg-gray-700 rounded-md">राजनिति</Link>
                <Link to="/legal-advice" className="block px-4 py-2 hover:bg-gray-700 rounded-md">कानूनी सलाह</Link>
                <Link to="/interviews" className="block px-4 py-2 hover:bg-gray-700 rounded-md">साक्षात्कार</Link>
                <Link to="/editorial" className="block px-4 py-2 hover:bg-gray-700 rounded-md">संपादकीय</Link>
              </div>
            )}
          </h2>
          {/* <Link to="/gold-smugling">सोना तस्करी</Link>
          <Link to="/transfer-posting">ट्रांसफर-पोस्टिंग</Link>
          <Link to="/politics">राजनिति</Link>
          <Link to="/legal-advice">कानूनी सलाह</Link>
          <Link to="/interviews">साक्षात्कार</Link>
          <Link to="/editorial">संपादकीय</Link> */}
        </div>
      </div>

      <div className='scroll-container md:z-30 -z-50 flex justify-center items-center'>
        <div className="container w-full  flex justify-center items-center  mx-auto">
          {newsList.slice(0, 1).map((news) => (
            <Link to={`/news/${news.id}`} key={news.id} className='scroll-text text-white text-lg'>
              {/* <Link to={`banner-news/${news.link}`}> */}

              <h2 className='font-bold'>{news.heading}</h2>
              {/* </Link> */}

              {/* <img src={news.imageUrl} alt="News" className='w-full h-48 object-cover py-3' />
                                <p className='text-sm'>{news.paragraph}</p>
                                <p className='text-xs py-3'>{news.view_paragraph}</p>
                                <p className='text-xs pb-3'>Type: {news.type}</p> */}
              {/* <button onClick={() => deleteNews(news.id)} className='bg-red-500 text-white p-2 rounded-md'>Delete</button> */}
            </Link>
          ))}
          {/* <Link to='/pricing' className="scroll-text text-white text-lg">"Excusive Offer For Students:<span className='font-extrabold'> Flat 50% off </span> on Revenue News Premium Subscription - <span className='font-extrabold'>Click Here To Avail Now!</span></Link> */}
        </div>
      </div>
      {/* {isSmallScreen && (
        <div
          className={`bg-[#AC7AF7] w-full flex flex-col justify-center items-center text-center overflow-y-auto ${menuOpen ? "visible" : "hidden"
            } transition-all duration-300`}
        >
          <div className="pl-4 pr-4">
            <div className="flex-col text-primary my-2">
              <Link
                to="/"
                label="Home"
                onClick={() => {
                  toggleMenu();
                }}
              />
              <Link
                to="/about-us"
                label="About Us"
                onClick={() => {
                  toggleMenu();
                }}
              />
              <Link
                to="/invest"
                label="Invest"
                onClick={() => {
                  toggleMenu();
                }}
              />
            </div>
          </div>
        </div>
      )} */}
    </div >
  )
}

export default Header