import React, { useEffect, useState } from 'react';
import { db } from '../subscribe/firebase';
import { collection, getDocs, query, orderBy } from 'firebase/firestore';
import Header from './Header';
import Footer from './Footer';
import { Link } from 'react-router-dom';

const Interview = () => {
    const [content, setContent] = useState([]);

    useEffect(() => {
        const fetchContent = async () => {
            const q = query(collection(db, 'content'), orderBy('timestamp', 'desc'));
            const querySnapshot = await getDocs(q);
            const contentList = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
            setContent(contentList);
        };

        fetchContent();
    }, []);

    return (
        <>
            <Header />
            <div className="grid md:grid-cols-3 grid-cols-1 gap-6 xl:px-10 2xl:px-40 lg:px-4 md:px-3 px-3 py-6 container w-auto md:w-full mx-auto">
                {content.map(item => (
                    <div key={item.id} className="content-item w-[360px]">
                        <h2 className='py-3 font-bold text-xl'>{item.name.length > 50 ? `${item.name.slice(0, 50)}...` : item.name}</h2>
                        <Link
                            to={item.videoUrl}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="relative inline-block"
                        >
                            <img
                                src={item.imageUrl}
                                alt={item.name}
                                className="h-60 w-[360px] object-cover"
                            />
                            <img
                                src={require('../assets/image/video-play-btn-black.png')}
                                alt="Play"
                                className="absolute top-[40%] left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-12 h-12"
                                style={{ filter: 'invert(1) brightness(0)' }}
                            />

                            <h2 className='py-3 underline text-blue-400'>{item.videoUrl.length > 50 ? `${item.videoUrl.slice(0, 45)}...` : item.videoUrl}</h2>
                        </Link>
                    </div>
                ))}
            </div>
            <Footer />
        </>
    );
};

export default Interview;
