import React from 'react'
import { IoLogoFacebook } from "react-icons/io";
import { IoLogoInstagram, IoLogoLinkedin, IoLogoTwitter, IoLogoWhatsapp, IoLogoYoutube } from "react-icons/io5";
import { Link, useNavigate } from 'react-router-dom';
import useScrollToTop from './useScrollToTop';

const Footer = () => {
    useScrollToTop();
    return (
        <div className='bg-[#262626] text-white '>
            <div className='border-b border-white container mx-auto large-screen-image'>
                <div className='xl:px-10 2xl:px-40 lg:px-20 md:px-10 px-3 py-8 w-full'>
                    <div className=''>
                        <div className='md:flex xl:gap-20 gap-6 p-6'>
                            <Link to='/who-we-are' style={{whiteSpace : 'nowrap'}}>
                                <h2 className='font-bold'>हम कौन हैं</h2>
                            </Link>
                            <Link to='/contact-us' style={{whiteSpace : 'nowrap'}}>
                                <h2 className='font-bold'>संपर्क करें</h2>
                            </Link>
                            <Link to='/' style={{whiteSpace : 'nowrap'}}>
                                <h2 className='font-bold flex'>हमारे साथ विज्ञापन करें</h2>
                            </Link>
                            <Link to='/' style={{whiteSpace : 'nowrap'}}>
                                <h2 className='font-bold'>करियर</h2>
                            </Link>
                            <Link to='/privacy-policy' style={{whiteSpace : 'nowrap'}}>
                                <h2 className='font-bold'>गोपनीयता नीति</h2>
                            </Link>
                            <Link to='/term-and-conditions' style={{whiteSpace : 'nowrap'}}>
                                <h2 className='font-bold'>नियम और शर्तें</h2>
                            </Link>
                            <div className=''>
                                <img src={require("../assets/image/free/footer_playstore-removebg-preview.png")} alt="footer2" className='' />
                                {/* <img src={require("../assets/image/free/footer_appstore-removebg-preview.png")} alt="footer1" className='lg:h-16 lg:w-[50%] w-[100%] md:w-[50%]' /> */}
                            </div>
                        </div>
                        <div className='flex justify-center items-center xl:gap-6 gap-3 '>
                            <IoLogoFacebook size={25} />
                            <IoLogoTwitter size={25} />
                            <IoLogoWhatsapp size={25} />
                            <IoLogoYoutube size={25} />
                            <IoLogoLinkedin size={25} />
                            <IoLogoInstagram size={25} />
                        </div>
                    </div>
                </div>
            </div>

            <div className='flex flex-col justify-center items-center py-6 container mx-auto'>
                <h2>2024 © सभी अधिकार सुरक्षित @Revenue News</h2>
                {/* <h2>होकालवायर द्वारा संचालित</h2> */}
            </div>
        </div>
    )
}

export default Footer
