import React, { useState } from 'react';
import { storage } from '../subscribe/firebase'; // Make sure to export your Firebase configuration from firebase.js
import { ref, uploadBytes, getDownloadURL, deleteObject } from 'firebase/storage';

const UploadPDF = () => {
  const [file, setFile] = useState(null);
  const [pdfUrl, setPdfUrl] = useState('');
  const [pdfName, setPdfName] = useState('');

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleUpload = () => {
    if (file) {
      const pdfRef = ref(storage, `pdfs/${file.name}`);
      uploadBytes(pdfRef, file).then((snapshot) => {
        getDownloadURL(snapshot.ref).then((url) => {
          setPdfUrl(url);
          setPdfName(file.name); // Save the PDF name to use for deletion
          console.log('PDF available at', url);
        });
      });
    }
  };

  const handleDelete = () => {
    if (pdfName) {
      const pdfRef = ref(storage, `pdfs/${pdfName}`);
      deleteObject(pdfRef)
        .then(() => {
          setPdfUrl('');
          setPdfName('');
          console.log('PDF deleted successfully');
        })
        .catch((error) => {
          console.error('Error deleting PDF:', error);
        });
    }
  };

  return (
    <div>
      <input type="file" accept=".pdf" onChange={handleFileChange} />
      <button onClick={handleUpload} className='bg-blue-500 rounded-md p-1'>Upload PDF</button>
      {pdfUrl && (
        <div className='gap-3'>
          <h2>PDF URL:</h2>
          <a href={pdfUrl} target="_blank" rel="noopener noreferrer" className='bg-blue-500 rounded-md p-1'>
            View PDF
          </a>
          <button onClick={handleDelete} className='bg-red-500 rounded-md p-1'>Delete PDF</button>
        </div>
      )}
    </div>
  );
};

export default UploadPDF;
