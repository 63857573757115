import React, { useState, useEffect } from 'react';

const VisitorCounter = () => {
    const [visitorCount, setVisitorCount] = useState(null);

    useEffect(() => {
        fetch('/visit')
            .then(response => response.text())
            .then(count => setVisitorCount(count))
            .catch(error => console.error('Error fetching visitor count:', error));
    }, []);

    return (
        <div>
            <h1>Website Visitors: {visitorCount}</h1>
        </div>
    );
};

export default VisitorCounter;
