import React, { useState, useEffect } from 'react';
import { db, storage } from '../subscribe/firebase'; // Import your Firebase configuration
import { addDoc, collection, getDocs, deleteDoc, doc } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL, deleteObject } from 'firebase/storage';
import { v4 as uuidv4 } from 'uuid';
import imageCompression from 'browser-image-compression';
import { toast } from 'react-toastify';

const InterviewPosting = () => {
    const [name, setName] = useState('');
    const [image, setImage] = useState(null);
    const [videoUrl, setVideoUrl] = useState('');
    const [newsList, setNewsList] = useState([]);

    // Fetch news items from Firestore
    useEffect(() => {
        const fetchNews = async () => {
            const newsCollection = collection(db, 'content');
            const newsSnapshot = await getDocs(newsCollection);
            const newsData = newsSnapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data()
            }));
            setNewsList(newsData);
        };

        fetchNews();
    }, []);

    // Handle image upload and news submission
    const handleUpload = async (e) => {
        e.preventDefault();

        if (image) {
            try {
                // Compress the image before uploading
                const options = {
                    maxSizeMB: 1, // Maximum size in MB
                    maxWidthOrHeight: 1920, // Maximum width or height in pixels
                    useWebWorker: true, // Use multi-threading for faster compression
                };

                const compressedImage = await imageCompression(image, options);

                const imageRef = ref(storage, `images/${uuidv4()}`);
                await uploadBytes(imageRef, compressedImage);
                const imageUrl = await getDownloadURL(imageRef);

                await addDoc(collection(db, 'content'), {
                    name: name,
                    imageUrl: imageUrl,
                    videoUrl: videoUrl,
                    timestamp: new Date()
                });

                toast.success('Interview Details sent successfully');

                // Reset the form
                setName('');
                setImage(null);
                setVideoUrl('');

                // Refresh the news list
                setNewsList([...newsList, { name, imageUrl, videoUrl, timestamp: new Date() }]);
            } catch (error) {
                toast.error(`Failed to upload image: ${error.message}`);
            }
        } else {
            toast.error('Please select an image to upload.');
        }
    };

    // Handle news deletion
    const handleDelete = async (newsId, imageUrl) => {
        try {
            // Delete the news document from Firestore
            await deleteDoc(doc(db, 'content', newsId));

            // Delete the image from Firebase Storage
            const imageRef = ref(storage, imageUrl);
            await deleteObject(imageRef);

            // Remove the deleted news item from the state
            setNewsList(newsList.filter(news => news.id !== newsId));

            toast.success('News deleted successfully');
        } catch (error) {
            toast.error(`Failed to delete news: ${error.message}`);
        }
    };

    return (
        <>
            <form onSubmit={handleUpload} className='gap-3'>
                <input
                    type="text"
                    placeholder="Name"
                    className='border p-1'
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    required
                />
                <input
                    type="file"
                    onChange={(e) => setImage(e.target.files[0])}
                    required
                />
                <input
                    type="text"
                    placeholder="YouTube Video URL"
                    className='border p-1'
                    value={videoUrl}
                    onChange={(e) => setVideoUrl(e.target.value)}
                    required
                />
                <button type="submit" className='bg-blue-500 p-2 rounded-md'>Upload</button>
            </form>

            <div className="grid grid-cols-3">
                {newsList.map((news) => (
                    <div key={news.id} className="news-item border mt-4 p-4">
                        <h2>{news.name}</h2>
                        <img src={news.imageUrl} alt={news.name} className='h-60 w-60' />
                        <p>{news.videoUrl}</p>
                        <button onClick={() => handleDelete(news.id, news.imageUrl)} className='bg-red-600 p-2 rounded-md'>Delete</button>
                    </div>
                ))}
            </div>
        </>
    );
};

export default InterviewPosting;
