import { collection, getDocs } from 'firebase/firestore';
import { ref, listAll, getDownloadURL } from 'firebase/storage';
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { db, storage } from '../subscribe/firebase';
import { IoSearch } from 'react-icons/io5';

const SearchBar = () => {
    const [searchInput, setSearchInput] = useState('');
    const [searchResults, setSearchResults] = useState([]);
    const [isDropdownVisible, setDropdownVisible] = useState(false);
    const [newsList, setNewsList] = useState([]);
    const [contentList, setContentList] = useState([]);
    const [pdfs, setPdfs] = useState([]);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchNews = async () => {
            try {
                const newsCollection = collection(db, 'news');
                const newsSnapshot = await getDocs(newsCollection);
                const newsList = newsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
                setNewsList(newsList);
                setLoading(false);
            } catch (error) {
                console.error(`Failed to fetch news: ${error.message}`);
                setLoading(false);
            }
        };

        fetchNews();
    }, []);

    useEffect(() => {
        const fetchContent = async () => {
            try {
                const contentCollection = collection(db, 'content');
                const contentSnapshot = await getDocs(contentCollection);
                const contentList = contentSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
                setContentList(contentList);
                setLoading(false);
            } catch (error) {
                console.error(`Failed to fetch content: ${error.message}`);
                setLoading(false);
            }
        };

        fetchContent();
    }, []);

    useEffect(() => {
        const fetchPDFs = async () => {
            try {
                const pdfRef = ref(storage, 'pdfs/');
                const res = await listAll(pdfRef);

                const pdfUrls = await Promise.all(
                    res.items.map(async (itemRef) => {
                        const url = await getDownloadURL(itemRef);
                        return { name: itemRef.name, url };
                    })
                );

                setPdfs(pdfUrls);
            } catch (error) {
                console.error('Error fetching PDFs:', error);
            }
        };

        fetchPDFs();
    }, []);

    const handleInputChange = (e) => {
        const value = e.target.value;
        setSearchInput(value);
        if (value) {
            filterSearchResults(value);
        } else {
            setSearchResults([]);
            setDropdownVisible(false);
        }
    };

    const filterSearchResults = (query) => {
        const combinedList = [
            ...newsList.map(item => ({ ...item, type: 'news' })),
            ...contentList.map(item => ({ ...item, type: 'content' })),
            ...pdfs.map(item => ({ ...item, type: 'pdf' }))
        ];
        const filteredResults = combinedList.filter(item => {
            if (item.heading && typeof item.heading === 'string') {
                return item.heading.toLowerCase().includes(query.toLowerCase());
            }
            if (item.name && typeof item.name === 'string') {
                return item.name.toLowerCase().includes(query.toLowerCase());
            }
            return false;
        });
        setSearchResults(filteredResults);
        setDropdownVisible(true);
    };

    const handleResultClick = (result) => {
        if (result.type === 'news')   {
            navigate(`/news/${result.id}`);
        } else if (result.type === 'pdf') {
            navigate(`/transfer-posting`);
        }else if (result.type === 'content'){
            navigate(`/interviews`);
        }
        setSearchInput('');
        setSearchResults([]);
        setDropdownVisible(false);
    };

    return (
        <div className='search-container w-full'>
            <div className='flex gap-3 md:w-[70%]'>
                <input
                    type="text"
                    placeholder=""
                    value={searchInput}
                    onChange={handleInputChange}
                    onFocus={() => setDropdownVisible(true)}
                    onBlur={() => setTimeout(() => setDropdownVisible(false), 200)}
                    className='border p-2 w-full md:px-3'
                    disabled={loading}
                />
                <button type="submit" className='bg-red-600 p-3 px-8 text-white mx-3 rounded-md'>
                    <IoSearch />
                </button>
            </div>
            {isDropdownVisible && searchResults.length > 0 && (
                <div className='search-dropdown'>
                    {searchResults.map((result) => (
                        <div
                            key={result.id || result.name}  // Unique key for PDFs and others
                            onClick={() => handleResultClick(result)}
                            className='search-result-item'
                        >
                            {result.heading || result.name}
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export default SearchBar;
